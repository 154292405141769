import React from "react"
import { getTaxoLien } from "../../hooks/hooks"

const phoneAzure = ({ addClass = "lh-32" }) => {
  const numPhone = getTaxoLien("numero telephone")
  return (
    <>
      <span
        style={{ height: "15px", width: "15px" }}
        className="icon-operator mr-1"
      ></span>
      <a
        className={"c-1 fs-16 ff-gb  td-n " + addClass}
        href={`tel:${numPhone}`}
      >
        {numPhone}
      </a>{" "}
      <span className="lh-16 fs-10 cg-3 ml-1 ">appel non surtaxé</span>
    </>
  )
}

export default phoneAzure
